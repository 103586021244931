import './style.css'
import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import * as dat from 'lil-gui'



const gui = new dat.GUI()

const scene = new THREE.Scene()




/*
JavaScript
*/
var img_data;
var mesh
let newplane
let texture;
// let textureLoader = new THREE.TextureLoader
const canvas = document.querySelector('canvas.webgl')
var photo = null;
var width = window.innerWidth; // We will scale the photo width to this
var height = window.innerHeight;
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

var video = document.getElementById("video_obj");
video.style.width = "100%";
// video.style.height = "100%";
video.setAttribute("autoplay", "");
video.setAttribute("muted", "");
video.setAttribute("playsinline", "");

var facingMode = "environment";
var constraints = {
  audio: false,
  video: {
    facingMode: facingMode,
  },
};
navigator.mediaDevices.getUserMedia(constraints).then(function success(stream) {
  video.srcObject = stream;
  video.play();
  width = video.offsetWidth;
  height = video.offsetHeight;
});

var frame = document.getElementById("canvas_obj");
frame.style.width = "100%";
frame.style.height = "100%";
// video.style.padding = '50px';

photo = document.getElementById("photo");

function clearphoto() {
  var context = frame.getContext("2d");
  context.fillStyle = "#AAA";
  context.fillRect(0, 0, frame.width, frame.height);

  var data = frame.toDataURL("image/png");
  photo.setAttribute("src", data);
}

function takepicture() {
  if(true)
  {
    var context = frame.getContext("2d");
    if (width && height) {
      clearphoto();
      frame.width = width;
      frame.height = height;
      context.drawImage(video, 0, 0, width, height);
      let frameURL = frame.toDataURL("image/png")
      console.log("TOOK PICTURE");
      var imageData = context.getImageData(0, 0, frame.width, frame.height);
      img_data = imageData.data;
      var zrng = 200;
      var zcen = 100;
      var nstr = 4;
      let minZ = 1000000;
      let maxZ = -1000000;
      var stepHeight = zrng / nstr;
      var depth_data = new Array();
      var uv_data = new Array();
      var color_data = new Array();
      var count;
      // console.log(buffGeo)
      
      var posData = buffGeo.getAttribute('position');
      var uvData = buffGeo.getAttribute('uv');
      
      // console.log( posData );
      // console.log( uvData );
      for (let i = 0; i < img_data.length; i += 4) {
        const avg = (img_data[i] + img_data[i + 1] + img_data[i + 2]) / 3;
        // console.log(avg);
        const Ir = img_data[i] / 255.0; // red
        const Ig = img_data[i + 1] / 255.0; // green
        const Ib = img_data[i + 2] / 255.0; // blue
        const wrappedPhaseHF = Math.atan2(Ir - 0.5, Ig - 0.5);
        const unwrappedPhaseLF = Ib * 2 * Math.PI - Math.PI;
        const K = Math.round((unwrappedPhaseLF * (zrng / stepHeight) - wrappedPhaseHF) /(2 * Math.PI));
        const unwrappedPhaseHF = wrappedPhaseHF + 2 * Math.PI * K;
        const recoveredZ = (unwrappedPhaseHF * stepHeight) /(2* Math.PI) + zcen;
        const output = (recoveredZ) + 400;
        if (recoveredZ > maxZ) {
          maxZ = recoveredZ;
        }
        if (recoveredZ < minZ) {
          minZ = recoveredZ;
        }
        const row = Math.floor((i/4))/frame.width;
        const col = (i/4)%frame.width;
        if ((output == 0))
          {
            console.log(row, col)
            console.log(output)
          }
        depth_data.push((i/4)%frame.width - frame.width/2)
        depth_data.push(-Math.floor((i/4)/frame.width) + frame.height/2)
        depth_data.push(output)
        
        
        uv_data.push( col / frame.width );
        uv_data.push( row / frame.height );
        
        
        color_data.push( Ir, Ig, Ib )

        img_data[i] = output * 255;
        img_data[i + 1] = output * 255;
        img_data[i + 2] = output * 255;
        img_data[i + 3] = 255;
        count = i;
      }
      // console.log(minZ,maxZ)
      // plane.geometry.needsUpdate = true
      // console.log(count/4)
      // console.log(frame.width, frame.height)
      
      
      context.putImageData(imageData, 0, 0);
      // console.log(frameURL)
      let frameURLdepth = frame.toDataURL("image/png")
      var set_data = new Float32Array(depth_data)
      var set_uv = new Float32Array( uv_data );
      // planegeo.setAttribute("position", new THREE.BufferAttribute(set_data, 3));
      buffGeo.setAttribute("position", new THREE.BufferAttribute(set_data,3))
      buffGeo.setAttribute("uv", uvData);
      buffGeo.setAttribute( 'color', new THREE.Float32BufferAttribute( color_data, 3 ) );
      // let depthTex = textureLoader.load(frameURLdepth)
      // texture = textureLoader.load(frameURL)
      // texture.minFilter = THREE.NearestFilter
      // texture.maxFilter = THREE.NearestFilter
      // texture.generateMipmaps = false
      // console.log(texture)
      // const material = new THREE.PointsMaterial()
      // material.displacementMap = depthTex
      // material.displacementScale = 200
      
      // material.wireframe = true;
      // gui.add(material, 'wireframe').name('inloopmat')
      // if (typeof mesh !== 'undefined')
      //   {
      //     scene.remove(mesh)
      //   }
      //       if (typeof newplane !== 'undefined')
      //   {
      //     scene.remove(newplane)
      //   }
      
      
      // newplane = new THREE.Mesh(
       // new THREE.PlaneBufferGeometry(frame.width,frame.height,frame.width,frame.height),
       // material
         // )
      // newplane.geometry.setAttribute('uv2', new THREE.BufferAttribute(newplane.geometry.attributes.uv.array,2))
      // scene.add(newplane)
      // console.log(mesh);
      
      // planegeo.needsUpdate = true
      // planegeo.computeVertexNormals()
      // photo.setAttribute("src", data);
    } else {
      clearphoto();
      console.log("CLEARED PHOTO");
    }
  } else {
  //IF ENDS HERE
  const geometry = new THREE.BufferGeometry();
  const vertices = new Float32Array( [
	-1.0, -1.0,  1.0,
	 1.0, -5.0,  1.0,
	 1.0,  1.0,  3.0,

	 1.0,  1.0,  3.0,
	-1.0,  1.0,  1.0,
	-1.0, -1.0,  1.0
] );

// itemSize = 3 because there are 3 values (components) per vertex
// geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
// const material = new THREE.MeshBasicMaterial( { color: 0xffffff } );
  // material.wireframe = true
// const mesh = new THREE.Mesh( geometry, material );
    // mesh.rotation.z = Math.PI
  // scene.add(mesh)
  }
}


var buffGeo = new THREE.PlaneBufferGeometry( video.offsetWidth, video.offsetHeight, video.offsetWidth, video.offsetHeight);
const material = new THREE.MeshStandardMaterial( {side: THREE.DoubleSide,
                                               vertexColors: true,
                                              shadowSide: THREE.BackSide,
                                                 metalness: 0.5,
                                                 roughness: 0.5} );
gui.add(material, 'wireframe')
mesh = new THREE.Mesh(buffGeo, material);
gui.add(mesh, 'visible')
scene.add(mesh)
document.addEventListener('keydown', event => {
  if (event.code === 'Space') {
  takepicture();
    event.preventDefault(); 
  }
});
video.addEventListener('click', event => {
  takepicture();
})




/*
THREE JS CODE
*/
const texLoader = new THREE.TextureLoader()

// const material = new THREE.MeshStandardMaterial()
// material.metalness = 0.7
// material.roughness = 0.2
// material.side = THREE.DoubleSide
// const planegeo = new THREE.PlaneBufferGeometry(frame.width * 2,frame.height * 2, frame.width, frame.height)
// const plane = new THREE.Mesh(
//   planegeo,
//   material
// )
// material.wireframe = true
// scene.add(plane)
// plane.visible = false
// gui.add(plane, 'visible')

const ambientLight = new THREE.AmbientLight(0xffffff, 1.4)
scene.add(ambientLight)

const pointLight = new THREE.PointLight(0xffffff,0.2)
pointLight.position.set(2000,0,4000)
scene.add(pointLight)


scene.background = new THREE.Color(0x72645b);
// scene.fog = new THREE.Fog(0x72645b, 500, 15000);


const axesHelper = new THREE.AxesHelper( 5000 );
scene.add( axesHelper );

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

const stats = Stats()
document.body.appendChild(stats.dom)

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 5000)
camera.position.x = 500
camera.position.y = 500
camera.position.z = 1000
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    // Update Objects
    if (typeof mesh !== 'undefined')
      {
        
        // mesh.rotation.y = 0.5 * elapsedTime
        
      }
   

    // Update controls
    controls.update()

    stats.update()
    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()



